import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/mpkProject/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/mpkProject/find?id=${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/mpkProject/update?id=${data.id}`, data);
    else return axios.post(`/api/mpkProject/create`, data);
  },
  delete: function (id) {
    return axios.delete('/api/mpkProject/delete?id=' + id);
  },
  exportWord: function (request) {
    return Utils.downloadFile('/api/mpkProject/exportWordCard', 'MpkProjectCard.docx', request);
  },
  export: function (request) {
    return Utils.downloadFile('/api/mpkProject/export', '', request);
  },
  createChat: function (data) {
    return axios.post(`/api/mpkProject/CreateChatForProject?projectId=${data.projectId}&orgId=${data.orgId}`);
  },
  getChats: function (id) {
    return axios.get(`/api/mpkProject/getChats?id=${id}`);
  },
  projectsByCountry: function (request) {
    return axios.get('/api/mpkProject/projectsByCountry', { params: request });
  },
  getProjectStatistics: function (request) {
    return axios.get('/api/mpkProject/projectStatistics', { params: request });
  },
  getUploadTemplate: function () {
    return Utils.downloadFile('/api/mpkProject/getUploadTemplate', '', {});
  },
  async uploadFile(file) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`api/mpkProject/uploadData`, formData);
  },

  // получение списка наименований МПК, у которых есть связь с Проектами в БД
  getMpkProject: function () {
    return axios.get('/api/mpkProject/MpkFromProjects');
  },

  // получение ДИНАМИКА ИЗМЕНЕНИЯ ПОРТФЕЛЯ ПРОЕКТОВ МПК
  getDynamicsChangesProjectPortfolio(params) {
    return axios.get(`/api/mpkprojectanalytic/getyearlydynamicdiagram${params}`);
  },

  // получение ДИНАМИКА КОЛИЧЕСТВА, СТАТУСОВ, ВКЛЮЧЕНИЯ И ИСКЛЮЧЕНИЯ ПРОЕКТОВ МПК
  getComplexanAlyticDiagram(params) {
    return axios.get('/api/mpkprojectanalytic/getcomplexanalyticdiagram', { params });
  },

  // получение таблицы ДИНАМИКА КОЛИЧЕСТВА, СТАТУСОВ, ВКЛЮЧЕНИЯ И ИСКЛЮЧЕНИЯ ПРОЕКТОВ МПК
  getSearchProjectsForStatistics(params) {
    return axios.get('/api/mpkprojectanalytic/searchprojectsforstatistics', { params });
  },

  // получение КОЛИЧЕСТВО СОЗДАННЫХ РАБОЧИХ МЕСТ
  getNumberJobsCreated(params) {
    return axios.get('/api/mpkprojectanalytic/getemployeenumberhistogram', { params });
  },

  // получение списка заседаний МПК
  getMpkEventMeetingList() {
    return axios.get('/api/mpkprojectanalytic/getmpkeventmeetinglist');
  },

  // получение ПРОЕКТОВ В ПОРТФЕЛЕ МПК
  getProjectsMPKPortfolio(params) {
    return axios.get('/api/mpkprojectanalytic/getsuccessratecirclediagram', { params });
  },

  // получение списка динамики кол-ва квлючения/исключения проектов мпк
  getDynamicModelist() {
    return axios.get('/api/mpkprojectanalytic/getdynamicmodelist');
  },
};
